import React from 'react';
import styled from 'styled-components';
import inflection from 'inflection';
import {Link} from 'gatsby';
import {Icon, Pagination} from 'semantic-ui-react';
import ScreenReaderText from './ScreenReaderText';
import {colorsRaw} from '../../theme';

const NavLinks = styled.div`
  margin-bottom: 20px;
  & > div {
    display: inline-block;
    padding: 0.25rem;
  }

  .next-page {
    float: right;
  }

  a {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
    color: ${colorsRaw.grey};
  }
`;

const IndeterminatePagination = ({
  subject = 'Page',
  nextPageLink,
  previousPageLink,
}) => {
  const humanizedSubject = inflection.humanize(subject);
  return (
    <nav>
      <ScreenReaderText>{humanizedSubject} navigation</ScreenReaderText>
      <NavLinks>
        {previousPageLink && (
          <div>
            <Link to={previousPageLink}>
              <Icon name="long arrow alternate left" />
              &nbsp;Previous {humanizedSubject}
            </Link>
          </div>
        )}
        {nextPageLink && (
          <div className="next-page">
            <Link to={nextPageLink}>
              Next {humanizedSubject}&nbsp;
              <Icon name="long arrow alternate right" />
            </Link>
          </div>
        )}
      </NavLinks>
    </nav>
  );
};

const PaginationEx = ({
  indeterminate,
  subject,
  baseLink,
  currentPage,
  hasNextPage,
  hasPreviousPage,
  numPages,
}) => {
  if (indeterminate) {
    const prevPageLink = hasPreviousPage
      ? `${baseLink}${currentPage > 2 ? `/${currentPage - 1}` : ''}`
      : null;
    const nextPageLink = hasNextPage ? `${baseLink}/${currentPage + 1}` : null;
    return (
      <IndeterminatePagination
        subject={subject}
        previousPageLink={prevPageLink}
        nextPageLink={nextPageLink}
      />
    );
  }

  return <Pagination activePage={currentPage} totalPages={numPages} />;
};

export default PaginationEx;
