import React from 'react';
import styled from 'styled-components';
import {Header} from 'semantic-ui-react';
import {colors} from '../../theme';

const StyledHeader = styled.header`
  margin-bottom: 60px;
  text-align: center;
  border-bottom: none;

  .page-title {
    margin-top: 0;
    margin-bottom: 20px;
    font-family: Oswald, sans-serif;
    font-size: 32px;
    line-height: 36px;
    text-transform: uppercase;
    border-bottom: none;
    color: #2b2b2b;

    span {
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: ${colors.mutedTextColor};
    }
  }

  .page-subtitle {
    font-size: 13px;
    color: #66697a;
  }
`;

const TaxonomyPageTitle = ({leadText, children, subtitle}) => (
  <StyledHeader>
    <Header className="page-title" as="h1">
      {leadText && <span>{leadText}</span>}
      {children}
    </Header>
    {subtitle && <p className="page-subtitle">{subtitle}</p>}
  </StyledHeader>
);

export default TaxonomyPageTitle;
