import React from 'react';
import {graphql} from 'gatsby';
import Page from '../../site/layouts/Page';
import SEO from '../SEO/SEO';
import ArticlesListTemplate from './ArticlesListTemplate';
import PaginationEx from '../PaginationEx';
import TaxonomyPageTitle from '../TaxonomyPageTitle';

const ArticleCategoryArticles = props => {
  const {
    data: {
      ahp: {getArticleCategory: category},
    },
    pageContext,
  } = props;
  const {
    currentPage,
    hasNextPage,
    hasPreviousPage,
    baseLink,
    pagedLink,
  } = pageContext;
  const path = pagedLink || `/article-categories/${category.slug}`;
  return (
    <Page sidebar="articles">
      <SEO
        title={`${category.title} in Austin Texas`}
        description={category.content}
        pathname={path}
        keywords={[
          category.title,
          'Realtor Blog',
          'Austin Texas Realtor',
          'Black Realtor In Austin',
          'Angie Ufomata',
        ]}
      />
      <TaxonomyPageTitle
        leadText="Browsing Category"
        subtitle={category.content}>
        {category.title}
      </TaxonomyPageTitle>
      <ArticlesListTemplate articles={category.articles.nodes} />
      <PaginationEx
        indeterminate
        baseLink={baseLink || pagedLink}
        currentPage={currentPage}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
      />
    </Page>
  );
};

export default ArticleCategoryArticles;

export const query = graphql`
  query ArticleCategoryArticles(
    $categoryId: ID!
    $startCursor: String
    $perPage: Int!
  ) {
    ahp {
      getArticleCategory(id: $categoryId) {
        title
        content
        articles(paginate: {after: $startCursor, first: $perPage}) {
          ...ArticleListItemFragment
        }
      }
    }
  }
`;
